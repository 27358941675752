import type { SerializedStyles } from "@emotion/serialize";
import type { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import type { ChangeEvent, FocusEvent, MouseEvent, KeyboardEvent } from "react";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";

import { NoStyleButton } from "@aviary/components/Button";
import { l } from "@aviary/locales/i18n";
import type { AviarySize } from "@aviary/types";
import { useDebounce } from "@shared/hooks";
import { FontAwesomeIcon } from "@shared/react-fontawesome";

import * as styles from "./DeprecatedTextField.styles";

type TextFieldSize = Extract<AviarySize, "small" | "normal" | "large">;

interface TextFieldProps {
  /**
   * Sets the type of field
   *
   * @default text
   */
  type?: "text" | "email";
  /**
   * Sets the field to be a focused state
   *
   * @default false
   */
  isFocused?: boolean;
  /**
   * Sets the input to go fullwidth
   *
   * @default false
   */
  isFullWidth?: boolean;
  /**
   * Allows you to set if the field allows autocomplete
   *
   * @default false
   */
  isAutocompleteOn?: boolean;
  /**
   * Placeholder text for the search bar
   */
  placeholder?: string;
  /**
   * Search value entered by user
   */
  value: string;
  /**
   * Enables use of the `onDebouncedChange` callbacks
   *
   * @default false
   */
  isDebounced?: boolean;
  /**
   * Sets the debounce length for `onDebouncedChange` in milliseconds
   *
   * @default 400
   */
  debounceLength?: number;
  /** Disable the input */
  disabled?: boolean;
  /** Allows placement of an icon within the field */
  inputIcon?: IconDefinition;
  /**
   * Control where the location of the Icon is placed within the field
   *
   * @default start
   */
  iconPosition?: "start" | "end";
  /**
   * Change the size of the input
   *
   * @default normal
   */
  size?: TextFieldSize;
  /**
   * Renders an x icon at the end of the text field, used to clear the input
   *
   * @default false
   */
  hasClearButton?: boolean;
  /**
   * Callback when the input is cleared
   */
  onClearField?: () => void;
  /**
   * Callback on blur
   */
  onBlur?: () => void;
  /**
   * Callback on change
   */
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  /**
   * Callback, but debounced
   */
  onDebouncedChange?: (value: string) => void;
  /**
   * Callback on focus
   */
  onFocus?: (e: FocusEvent<HTMLInputElement>) => void;
  /**
   * Callback on key down
   */
  onKeyDown?: (e: KeyboardEvent) => void;
  /**
   * Callback on when the icon is clicked
   */
  onIconClick?: (e?: MouseEvent<HTMLButtonElement>) => void;
  /**
   * Option to add styles to the wrapper div
   */
  wrapperStyles?: SerializedStyles | SerializedStyles[];
}

const DeprecatedTextField = ({
  type = "text",
  isFocused = false,
  size = "normal",
  value,
  inputIcon,
  isFullWidth = false,
  iconPosition = "start",
  isDebounced = false,
  isAutocompleteOn = false,
  debounceLength = 400,
  disabled,
  hasClearButton = false,
  onDebouncedChange,
  onIconClick,
  onKeyDown,
  onClearField,
  wrapperStyles,
  ...rest
}: TextFieldProps) => {
  const debouncedCallback = useDebounce(inputVal => onDebouncedChange(inputVal), debounceLength);
  const { t } = useTranslation();
  const inputRef = useRef(null);

  const autoComplete = isAutocompleteOn ? "on" : "off";

  const textFieldBuilder = [
    styles.inputStyles,
    size && styles.inputSizes[size],
    inputIcon && styles.withIcon[iconPosition],
    isFullWidth && styles.fullwidth,
  ];

  const wrapperBuilder = [styles.wrapper, isFullWidth && styles.fullwidth, wrapperStyles];

  const iconBuilder = [
    styles.iconBase,
    size && styles.iconSizing[size],
    styles.iconLocations[iconPosition],
  ];

  const closeButtonBuilder = [styles.closeBase, size && styles.iconSizing[size]];

  useEffect(() => {
    if (isFocused) {
      inputRef.current.focus();
    }
  }, [isFocused]);

  useEffect(() => {
    if (isDebounced) {
      debouncedCallback(value);

      return () => debouncedCallback.cancel();
    }
  }, [value]);

  const handleKeyDown = e => {
    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  const renderClearButton = () => {
    if (hasClearButton && iconPosition !== "end") {
      return (
        <NoStyleButton
          aria-label={t(l.aviary.common.clear)}
          css={closeButtonBuilder}
          onClick={onClearField}
        >
          <FontAwesomeIcon icon={faTimes} />
        </NoStyleButton>
      );
    }
  };

  const renderIcon = () => {
    if (!inputIcon) return null;
    if (onIconClick) {
      return (
        <NoStyleButton css={iconBuilder} onClick={onIconClick}>
          <FontAwesomeIcon icon={inputIcon} />
        </NoStyleButton>
      );
    }

    return (
      <div css={iconBuilder}>
        <FontAwesomeIcon icon={inputIcon} />
      </div>
    );
  };

  return (
    <div css={wrapperBuilder}>
      {renderIcon()}
      <input
        type={type}
        ref={inputRef}
        css={textFieldBuilder}
        value={value}
        autoComplete={autoComplete}
        onKeyDown={handleKeyDown}
        disabled={disabled}
        {...rest}
      />
      {renderClearButton()}
    </div>
  );
};

export { DeprecatedTextField, type TextFieldProps };
