import type { QueryHookOptions } from "@apollo/client";
import { useQuery, gql } from "@apollo/client";

import type { Flipper } from "@shared/types/graphqlGenerated";

const FLIPPERS_SHARED_QUERY = gql`
  query Flippers_Shared_Query {
    viewer {
      flippers {
        id
        name
        result
      }
    }
  }
`;

type FlipperFragment = Pick<Flipper, "id" | "name" | "result">;
interface FlippersData {
  viewer: { flippers: FlipperFragment[] };
}

const useFlippersQuery = (options?: QueryHookOptions<FlippersData>) =>
  useQuery<FlippersData>(FLIPPERS_SHARED_QUERY, options);

export type { FlippersData };
export { useFlippersQuery, FLIPPERS_SHARED_QUERY };
