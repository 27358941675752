import { css, type Theme } from "@emotion/react";
import { typography } from "aviary-tokens";

import { Size } from "@shared/types/sizes";
import { animations, baseStyles, helpers, dimensions } from "@styles";
import * as timing from "@styles/emotion-styles/timing";

const MEDIUM_TRACK_HEIGHT = "1.5rem";
const SMALL_TRACK_HEIGHT = "1.24rem";
const MEDIUM_WIDTH = "6rem";
const MEDIUM_HEIGHT = "1.625rem";
const SMALL_HEIGHT = "1.25rem";

export const track = {
  base: (theme: Theme) => css`
    ${animations.transition()};
    background-color: ${theme.info.backgroundBase};
    border-radius: 1rem;
    height: ${MEDIUM_TRACK_HEIGHT};
    width: 3rem;

    &:active,
    &:focus {
      background-color: ${theme.info.backgroundHover};
      box-shadow: 0 0 0 0.25rem ${helpers.hexToRgba(theme.info.backgroundBase, 0.15)};
    }
  `,
  isOff: (theme: Theme) => css`
    background-color: ${theme.system.borderBase};
    box-shadow: none;

    &:hover,
    &:active {
      background-color: ${theme.system.backgroundMutedHover};
    }
  `,
  [Size.SMALL]: css`
    height: ${SMALL_TRACK_HEIGHT};
    width: 2.25rem;
  `,
  hasHoverStyle: (theme: Theme) => css`
    &:hover {
      background-color: ${theme.info.backgroundHover};
    }
  `,
  isDisabled: (theme: Theme) => css`
    background-color: ${theme.system.borderBase};

    &:hover {
      background-color: ${theme.system.borderBase};
    }
  `,
};

export const ball = {
  base: (theme: Theme) => css`
    width: ${MEDIUM_TRACK_HEIGHT};
    height: ${MEDIUM_TRACK_HEIGHT};
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
      0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${theme.system.backgroundBase};
    transition: left ${timing.ultraFast} ${animations.easeInOutQuad} 0ms,
      transform ${timing.ultraFast} ${animations.easeInOutQuad} 0ms;
  `,
  isOn: css`
    left: 50%;
    top: 0;
  `,
  small: css`
    width: ${SMALL_TRACK_HEIGHT};
    height: ${SMALL_TRACK_HEIGHT};
    & {
      top: -1px;
    }
  `,
  isDisabled: (theme: Theme) => css`
    background-color: ${theme.disabled.inputLabelText};
  `,
};

export const toggle = {
  base: css`
    ${baseStyles.controlBase};
    align-content: center;
    height: ${MEDIUM_HEIGHT};
    display: flex;
  `,
  [Size.SMALL]: css`
    height: ${SMALL_HEIGHT};
  `,
};

export const toggleWrapper = {
  base: (theme: Theme) => css`
    display: flex;
    gap: 0.5rem;

    min-width: ${MEDIUM_WIDTH};
    width: fit-content;
    cursor: pointer;
    color: ${theme.text.body};
    padding: 0;

    @media (min-width: ${dimensions.phone}) {
      align-items: center;
    }
  `,
  isDisabled: css`
    cursor: not-allowed;
  `,
  isLoading: css`
    cursor: not-allowed;
  `,
};

export const label = {
  text: (theme: Theme) => css`
    color: ${theme.text.body};
    font-weight: ${typography.weightBase};
    line-height: 1.125;
  `,
  toggleIcon: css`
    align-self: center;
  `,
};
