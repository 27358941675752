import {
  Arrange,
  Dropdown,
  DropdownButton,
  DropdownContent,
  DropdownItem,
  Typography,
} from "@aviary";
import { useExperimentVar } from "@shared/context/experiment";
import { useBreakpoints, useSharedGlobalConfig } from "@shared/hooks";

type OnOverrideSetParam = { experimentName: string; group: string };

interface Props {
  experimentName: string;
  onOverrideSet: ({ experimentName, group }: OnOverrideSetParam) => void;
}

const Experiment = ({ experimentName, onOverrideSet }: Props) => {
  const { overrideValues, setOverride, currentExperiments } = useExperimentVar();
  const { isAdminImpersonating } = useSharedGlobalConfig();
  const { tablet } = useBreakpoints();

  const handleOverrideSet = (group: string) => {
    setOverride({ experimentName, group, isAdminImpersonating });
    onOverrideSet({ experimentName, group });
  };

  const renderGroup = (group: string) => {
    const selected = overrideValues[experimentName] === group;

    return (
      <DropdownItem key={group} onSelect={() => handleOverrideSet(group)} isHighlighted={selected}>
        {group}
      </DropdownItem>
    );
  };

  const renderGroups = () => currentExperiments[experimentName].map(renderGroup);

  return (
    <Arrange alignment="center" isVertical={tablet.lessThan} isFullWidth={tablet.lessThan}>
      <Typography type="h3">{experimentName}</Typography>
      <Dropdown isFullWidth={tablet.lessThan}>
        <DropdownButton>{overrideValues[experimentName]}</DropdownButton>
        <DropdownContent>{renderGroups()}</DropdownContent>
      </Dropdown>
    </Arrange>
  );
};

export { Experiment };
