import type { MutationHookOptions } from "@apollo/client";
import { useMutation, gql } from "@apollo/client";

import type { Flipper } from "@shared/types/graphqlGenerated";

const FLIPPER_UPDATE_SHARED_MUTATION = gql`
  mutation FlipperUpdate_Shared_Mutation($input: FlipperUpdateInput!) {
    flipperUpdate(input: $input) {
      flipper {
        id
        name
        result
      }
    }
  }
`;

type FlipperFragment = Pick<Flipper, "id" | "name" | "result">;

interface FlipperUpdateData {
  flipperUpdate: {
    flipper: FlipperFragment;
  };
}

interface Variables {
  input: {
    enable: boolean;
    flipper: String;
  };
}

const useFlipperUpdateMutation = (options?: MutationHookOptions<FlipperUpdateData, Variables>) =>
  useMutation<FlipperUpdateData, Variables>(FLIPPER_UPDATE_SHARED_MUTATION, options);

export type { FlipperUpdateData };
export { useFlipperUpdateMutation, FLIPPER_UPDATE_SHARED_MUTATION };
