import { faSync, type IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import type { ButtonHTMLAttributes } from "react";
import { useTranslation } from "react-i18next";

import { useIsDS4Enabled } from "@aviary/hooks/useIsDS4Enabled";
import { l } from "@aviary/locales/i18n";
import type { AviarySize } from "@aviary/types";
import { FontAwesomeIcon } from "@shared/react-fontawesome";

import { NoStyleButton } from "../Button/NoStyleButton";

import { DeprecatedToggle } from "./DeprecatedToggle";

import * as styles from "./Toggle.styles";

interface ToggleProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * Optionally hide/show the loading icon
   *
   * @default false
   */
  isLoading?: boolean;
  /**
   * Pass in if the toggle is "On" or "Checked"
   *
   * @default false
   */
  isOn?: boolean;
  /**
   * Optionally control the size of the toggle
   *
   * @default normal
   */
  size?: Extract<AviarySize, "small" | "normal">;
  /**
   * Override the "On" label for the toggle
   *
   * @default "On"
   */
  onLabel?: string;
  /**
   * Override the "Off" label for the toggle
   *
   * @default "Off"
   */
  offLabel?: string;
  /**
   * Option to add an icon on the ball
   */
  icon?: IconDefinition;
}

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/interaction/Toggle
 */
const Toggle = ({ size = "normal", onLabel = "On", offLabel = "Off", ...props }: ToggleProps) => {
  const { t } = useTranslation();

  const isDS4Enabled = useIsDS4Enabled();

  if (!isDS4Enabled) return <DeprecatedToggle {...{ size, onLabel, offLabel, ...props }} />;

  const { isLoading, isOn, disabled, onClick, icon, ...rest } = props;

  const getTrackStyle = [
    styles.track.base,
    styles.track[size],
    (!isOn || disabled) && styles.track.isOff,
    !isLoading && !disabled && isOn && styles.track.hasHoverStyle,
    disabled && styles.track.isDisabled,
  ];

  const getBallStyle = [
    styles.ball.base,
    styles.ball[size],
    isOn && styles.ball.isOn,
    disabled && styles.ball.isDisabled,
  ];

  const getToggleStyle = [styles.toggle.base, styles.toggle[size]];

  const getToggleWrapperStyle = [
    styles.toggleWrapper.base,
    styles.toggleWrapper[size],
    disabled && styles.toggleWrapper.isDisabled,
    isLoading && styles.toggleWrapper.isLoading,
  ];

  const getImageSize = size === "small" ? "sm" : "1x";

  const getLabelStyle = [styles.label.text, disabled && styles.label.isDisabled];

  const renderLabel = () => {
    if (isLoading) {
      return (
        <div data-testid="toggle-icon" css={styles.label.toggleIcon}>
          <FontAwesomeIcon
            size={getImageSize}
            icon={faSync}
            spin
            title={t(l.aviary.button.Loading)}
          />
        </div>
      );
    }

    return <label css={getLabelStyle}>{isOn ? onLabel : offLabel}</label>;
  };

  const handleClick = event => {
    if (!isLoading && !disabled) {
      onClick(event);
    }
  };

  const isOnStringified = isOn ? "true" : "false";

  return (
    <NoStyleButton
      data-testid="toggle"
      css={getToggleWrapperStyle}
      onClick={handleClick}
      aria-checked={isOnStringified}
      role="switch"
      {...rest}
    >
      <div css={getToggleStyle}>
        <div css={getTrackStyle} />
        <div css={getBallStyle}>{icon && <FontAwesomeIcon icon={icon} css={styles.icon} />}</div>
      </div>
      {renderLabel()}
    </NoStyleButton>
  );
};

export type { ToggleProps };
export { Toggle };
