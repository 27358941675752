import { Drawer as MaterialDrawer } from "@mui/material";
import type { DrawerProps } from "@mui/material/Drawer/Drawer";
import type { MutableRefObject, ReactNode } from "react";

import type { AviarySize } from "@aviary/types";
import { Direction } from "@aviary/types";
import { useBreakpoints } from "@shared/hooks";

import * as styles from "./Drawer.styles";

type DrawerSize = Extract<AviarySize, "small" | "medium" | "large">;

interface Props extends DrawerProps {
  /**
   * Optionally renders the drawer
   */
  isOpen: boolean;
  /**
   * Optionally adds a backdrop behind the drawer
   */
  hasBackdrop: boolean;
  /**
   * Sets the direction the drawer opens from
   */
  opensFrom?: Direction;
  /**
   * A callback for when the user clicks outside a drawer with a backdrop
   */
  onClickOutside?: () => void;
  /**
   * Optionally have the children render in a flex column wrapper
   */
  isFlexColumn?: boolean;
  /**
   * Adds a z-index of 8850
   */
  isMaxIndex?: boolean;
  drawerChildRef?: MutableRefObject<HTMLDivElement>;
  children: ReactNode;
  /**
   * Optionally changes the width of the drawer
   *
   * @default medium
   */
  size?: DrawerSize;
  /**
   * Used only for deep linking drawer to remove min-height
   * @default false
   */
  isDeepLinking?: boolean;
}

/**
 * Documentation:
 * https://aviary.docs.fullscript.cloud/layout/Drawer
 */
const Drawer = ({
  isOpen,
  onClickOutside,
  opensFrom = Direction.RIGHT,
  hasBackdrop,
  children,
  isMaxIndex,
  drawerChildRef,
  size = "medium",
  isFlexColumn,
  isDeepLinking = false,
  ...rest
}: Props) => {
  const springTransitionSpeed = 200;

  const { tablet } = useBreakpoints();

  const conditionalDrawerStyles = () => {
    return [styles.drawer, isMaxIndex && styles.maxIndexDrawer];
  };

  const anchor: Direction = tablet.lessThan ? Direction.BOTTOM : opensFrom;
  const isFromBottom = opensFrom === Direction.BOTTOM;
  const isFromTopOrBottom = opensFrom === Direction.TOP || isFromBottom;
  const isDeepLinkingDrawer = isDeepLinking && isFromBottom;

  return (
    <MaterialDrawer
      transitionDuration={springTransitionSpeed}
      css={conditionalDrawerStyles()}
      data-testid="drawer-wrapper"
      open={isOpen}
      hideBackdrop={!hasBackdrop}
      onBackdropClick={onClickOutside}
      anchor={anchor}
      role="region"
      {...rest}
    >
      <section
        css={[
          isFromTopOrBottom && styles.opensFrom[opensFrom],
          isDeepLinkingDrawer && styles.isDeepLinking,
          !isFromTopOrBottom && styles.opensFrom[opensFrom][size],
          styles.container,
        ]}
      >
        <div
          css={[
            !isDeepLinkingDrawer && styles.fullHeightContainer,
            isFlexColumn && styles.flexColumn,
          ]}
          ref={drawerChildRef}
        >
          {children}
        </div>
      </section>
    </MaterialDrawer>
  );
};

export { Drawer };
export type { Props as DrawerProps };
